import React from "react"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import Layout from "../components/layout"
import BackgroundVector from "../components/backgroundVector"
import ContactForm from "../components/contactForm"
import Hero, { HeroTitleDescription } from "../components/Hero"
import PageContentContainer from "../components/PageContentContainer"
import BannerContainer from "../components/bannerContainer"
import WaveDivider from "../components/svgs/WaveDivider"
import BannerTitleNew from "../components/BannerTitleNew"
import EventCards from "../components/EventCard"
import { formatTime, monthArray, reshapeEventData } from "../helperFunctions"
import { sanityConfig } from "../sanityConfig"
import RenderEvents from "../components/events/RenderEvents"

const EventsPage = ({ data }) => {
  const { eventsPageData } = data
  const heroImageData =
    eventsPageData?.heroImage?.asset?.gatsbyImageData ||
    getImage(data.eventsHeroImage)
  const heroFluid = convertToBgImage(heroImageData).fluid

  const {
    events: { edges: events },
  } = data
  // Flatten/simplify array
  const newEvents = events.flatMap(event => [event.node])
  // shape data to work with EventCards component
  const reshapedEventData = reshapeEventData(newEvents)
  // Sort array by event date
  const sortedEventData = reshapedEventData.sort(
    (a, b) => b.dateObject - a.dateObject
  )

  // ----- This is to dynamically sort events into current and past -----
  // I have opted to use manual sorting with a boolean toggle in the CMS instead
  // Create date object for comparison
  // const todaysDate = new Date()

  // const upcomingEvents = sortedEventData.filter(
  //   event => event.dateObject >= todaysDate
  // )
  // const pastEvents = sortedEventData.filter(
  //   event => event.dateObject < todaysDate
  // )

  // For each event, extract info and put it in correct structure:

  return (
    <Layout>
      <SEO title="Events" />
      <Hero props={{ heroFluid }}>
        <HeroTitleDescription
          title="Events"
          text="Check this space for announcements about coming events!"
        />
      </Hero>
      <PageContentContainer>
        {/* UPCOMING EVENTS */}
        <BannerContainer color="bg-primary py-20" className="">
          <BannerTitleNew
            props={{
              divider: (
                <WaveDivider className="hidden md:block fill-tertiary w-20 md:w-32" />
              ),
              divStyle: "h-1/6 w-full place-items-center",
              doubleDivider: true,
              text: (
                <div className="grid justify-items-center">
                  <h2 className="text-3xl md:text-4xl font-semibold text-center">
                    Upcoming Events
                  </h2>
                  <WaveDivider className="fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                </div>
              ),
            }}
          />{" "}
          <RenderEvents />
        </BannerContainer>

        {/* PAST EVENTS */}
        <BannerContainer color="bg-secondary" className=" py-20">
          <BannerTitleNew
            props={{
              divider: (
                <WaveDivider className=" hidden md:block fill-tertiary w-20 md:w-32" />
              ),
              divStyle: "h-1/6 w-full place-items-center",
              doubleDivider: true,
              text: (
                <div className="grid justify-items-center">
                  <h2 className="text-3xl md:text-4xl font-semibold text-center text-primary">
                    Past Events
                  </h2>
                  <WaveDivider className="fill-tertiary px-5 w-1/2 mt-5 md:hidden" />
                </div>
              ),
            }}
          />

          <RenderEvents showPastEvents darkText />
        </BannerContainer>
      </PageContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query EventsPageQuery {
    eventsHeroImage: file(
      relativePath: { eq: "papaioannou-kostas-tysecUm5HJA-unsplash.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    events: allSanityEvent {
      edges {
        node {
          id
          title
          eventDateTime
          bannerText
          slug {
            current
          }
          bannerHeroImage {
            asset {
              id
              url
              gatsbyImageData(width: 200, aspectRatio: 1, placeholder: BLURRED)
            }
          }
        }
      }
    }
    eventsPageData: sanityEventsPage {
      heroImage {
        asset {
          gatsbyImageData(width: 1200, placeholder: BLURRED)
          id
        }
      }
    }
  }
`

export default EventsPage
